/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const triggerPopChatEvent = (payload = {}) => {
    const popOpenDesktopChat = new CustomEvent('popOpenChat', { detail: payload });

    window.dispatchEvent(popOpenDesktopChat);
};

export default triggerPopChatEvent;
